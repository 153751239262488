import { Inter } from "next/font/google";
import "../styles/globals.css";
import "../styles/App.scss";

import "../styles/trootech/trootech-normal.scss";
import "../styles/trootech/trootech-md-device.scss";
import "../styles/trootech/trootech-ipad.scss";
import "../styles/trootech/trootech-md-min-max.scss";
import "../styles/trootech/trootech-mobile.scss";

import "../styles/yash.scss";
import "../styles/contect-us.scss";
import "../styles/careers-page.scss";
import "../layout/common/footer-final.scss";
import "../layout/common/header-final.scss";
import "../layout/common/trootech-v2-slider.scss";
import "../layout/common/filter.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/request-proposal/request.scss";
import "../components/home-new/faq/faq.scss";
import "../components/home-new/contact-section/contact.scss";
import "../components/contact-us-new/global-section/global.scss";
import "../styles/color.scss";
import "../styles/main-style.scss";
import "../styles/use-case.scss";
import "react-toastify/dist/ReactToastify.css";

import store from "../store/store";
import Script from "next/script";
import { Provider } from "react-redux";
import React, { Suspense, createContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import dynamic from "next/dynamic";

import CookieConsent from "../components/common/CookieConsent/CookieConsent";

const FormLoader = dynamic(() => import("../components/common/FormLoader"));
const Analytics = dynamic(() => import("../components/gtm/Analytics"));

export const FormContext = createContext({
	showModal: false,
	showForm: () => console.log("FormContext Value Not Provided"),
	hideForm: () => console.log("FormContext Value Not Provided"),
});

const inter = Inter({
	subsets: ["latin"],
	display: "swap",
	preload: true,
	weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

function MyApp({ Component, pageProps }) {
	const [theme, setTheme] = useState("light");
	// const [loadScript, setLoadScript] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const storedTheme = localStorage.getItem("Theme");
			if (storedTheme) {
				setTheme(storedTheme);
			}
		}
	}, []);

	// const handleHoverOrTouch = () => {
	// 	console.log("Script is loaded");
	// 	setLoadScript(true);
	// };

	// useEffect(() => {

	// 	if (typeof window !== "undefined") {
	// 		document.body.addEventListener("mouseenter", handleHoverOrTouch);
	// 		document.body.addEventListener("touchstart", handleHoverOrTouch);

	// 		return () => {
	// 			document.body.removeEventListener("mouseenter", handleHoverOrTouch);
	// 			document.body.removeEventListener("touchstart", handleHoverOrTouch);
	// 		};
	// 	}
	// }, []);


	return (
		<Provider store={store}>

			<Script id="show-banner" strategy="lazyOnload">
				{`
				document?.getElementById('form-modal-open')
					? document.body.style.overflow = 'hidden'
					: document.body.style.overflow = 'initial';
				`}
			</Script>


			<Suspense>
				<Analytics />
			</Suspense>


			<Script id="clarity-script" type="text/javascript" strategy="lazyOnload">
				{`
				(function(c,l,a,r,i,t,y){
					c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
					t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
					y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
				})(window, document, "clarity", "script", "gxlypqpyub");
				`}
			</Script>

			<Script
				id="vtag-ai-js"
				async
				src="https://r2.leadsy.ai/tag.js"
				data-pid="1kxlgWjrLWXekvDa1"
				data-version="062024"
				strategy="lazyOnload"
			/>

			<FormLoader />
			<div className={inter.className}>
				<Component {...pageProps} />
			</div>

			<CookieConsent />

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme={theme}
			/>
		</Provider>
	);
}

export default MyApp;


// import { Inter } from "next/font/google";
// import "../styles/globals.css";
// import "../styles/App.scss";

// import "../styles/trootech/trootech-normal.scss";
// import "../styles/trootech/trootech-md-device.scss";
// import "../styles/trootech/trootech-ipad.scss";
// import "../styles/trootech/trootech-md-min-max.scss";
// import "../styles/trootech/trootech-mobile.scss";

// import "../styles/yash.scss"; /* carreer page, news page, agreement page, ccase studies page, modal  */
// import "../styles/contect-us.scss";
// import "../styles/careers-page.scss";
// import "../layout/common/footer-final.scss";
// import "../layout/common/header-final.scss";
// import "../layout/common/trootech-v2-slider.scss";
// import "../layout/common/filter.scss";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import "bootstrap/dist/css/bootstrap.min.css";

// import "../components/request-proposal/request.scss";

// import "../components/home-new/faq/faq.scss";
// import "../components/home-new/contact-section/contact.scss";
// import "../components/contact-us-new/global-section/global.scss";
// import "../styles/color.scss";

// import "../styles/main-style.scss";
// import "../styles/use-case.scss";
// import "react-toastify/dist/ReactToastify.css";

// import store from "../store/store";
// import Script from "next/script";
// import App from "next/app";
// import { Provider } from "react-redux";
// import React, { Suspense, createContext } from "react";
// import { ToastContainer } from "react-toastify";
// import dynamic from "next/dynamic";

// import CookieConsent from "../components/common/CookieConsent/CookieConsent";

// const FormLoader = dynamic(() => import("../components/common/FormLoader"));
// const Analytics = dynamic(() => import("../components/gtm/Analytics"));

// /* const SnowFlake = dynamic(() => import("../components/common/SnowFlake"), {
// 	ssr: false,
// }); */

// export const FormContext = createContext({
// 	showModal: false,
// 	showForm: () => console.log("FormContext Value Not Provided"),
// 	hideForm: () => console.log("FormContext Value Not Provided"),
// });

// const inter = Inter({
// 	subsets: ["latin"],
// 	display: "swap", // Avoids CLS
// 	preload: true,
// 	weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
// });

// class MyApp extends App {

// 	render() {
// 		//Information that was returned  from 'getInitialProps' are stored in the props i.e. pageProps
// 		const { Component, pageProps } = this.props;
// 		let getTheme = "light";
// 		if (typeof window !== "undefined") {
// 			getTheme = localStorage.getItem("Theme");
// 		}
// 		return (
// 			// <Sentry.ErrorBoundary>
// 			<Provider store={store}>
// 				<Suspense>
// 					<Analytics />
// 				</Suspense>

// 				<Script id="show-banner" strategy="lazyOnload">
// 					{`
// 							document?.getElementById('form-modal-open')
// 								? document.body.style.overflow = 'hidden'
// 								: document.body.style.overflow = 'initial'
// 						`}
// 				</Script>
// 				<Script
// 					id="clarity-script"
// 					type="text/javascript"
// 					strategy="lazyOnload"
// 				>
// 					{`
// 							(function(c,l,a,r,i,t,y){
// 								c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
// 								t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
// 								y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
// 							})(window, document, "clarity", "script", "gxlypqpyub");
// 						`}
// 				</Script>

// 				<Script
// 					id="vtag-ai-js"
// 					async
// 					src="https://r2.leadsy.ai/tag.js"
// 					data-pid="1kxlgWjrLWXekvDa1"
// 					data-version="062024"
// 					strategy="lazyOnload"
// 				></Script>

// 				<FormLoader />
// 				<div className={inter.className}>
// 					<Component {...pageProps} />
// 				</div>

// 				<CookieConsent />

// 				{/* <SnowFlake /> */}

// 				<ToastContainer
// 					position="top-right"
// 					autoClose={5000}
// 					hideProgressBar={false}
// 					newestOnTop={false}
// 					closeOnClick
// 					rtl={false}
// 					pauseOnFocusLoss
// 					draggable
// 					pauseOnHover
// 					theme={getTheme}
// 				/>
// 			</Provider>
// 			// </Sentry.ErrorBoundary>
// 		);
// 	}
// }

// export default MyApp;
