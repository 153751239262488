import { combineReducers, configureStore } from "redux";
// import filterReducer from "./filterReducer";
import loaderReducer from "./loaderReducer";
import headerReducer from "./headerReducer";
import sliderReducer from "./sliderReducer";

export default combineReducers({
    loaderReducer,
    headerReducer,
    sliderReducer,
	// filterReducer,
});
